import React, { Component } from 'react';
import "./Banner.css"
import banner from "../../resources/images/IMG_7497.jpeg"
class Banner extends Component {
    state = {}
    render() {
        return (
            <section className="banner">
                <div className="banner-holder">
                    <img src={banner} className="banner-image" alt="Banner" />
                    <div className="banner-cover">
                        <div className="banner-texts">
                            {/* <p>
                                Driving S4DI3
                             </p>
                            <p>
                                
                            </p> */}
                            {/* <div className="btn-midd">
                                <div className="btn-midd gradianet">
                                    <p>View Tour Dates</p>
                                    <i className=" icon-fast-fw-outline" />
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
                <div className="theStory-holder">
                    <div className="theStory-textframe">
                        <img src={require("../../resources/images/IMG_6266.jpg")} alt="Profile" className="centered-image" />  
                    </div> 
                </div>
                {/* <div className="theStory-holder">
                    <div className='bandcamp'>
                        <iframe width= "450px" height= "450px" 
                        src="https://bandcamp.com/EmbeddedPlayer/album=1281122132/size=large/bgcol=333333/linkcol=0f91ff/minimal=true/transparent=true/" seamless>
                            <a href="https://ontosystems.bandcamp.com/album/mutations-spontan-es-20th-century-r-sonance">mutations spontanées + 20th century@résonance by onto.systems</a>
                            </iframe>
                    </div> 
                </div> */}
                <div className="theStory-holder">
               
                <div className="theStory-textframe">
                    </div> 

                    <div className="vid1-holder">
                        <iframe class="vid1-frame" width="760" height="515" src="https://www.youtube.com/embed/QNkql--Raro?si=TxC5Q9O9kxmgH6hV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div> 
                      <div className="vid1-holder">
                            <iframe width="760" height="515" src="https://www.youtube-nocookie.com/embed/t7dv8558FI0?si=_qdF8J8Q5xN1Qq9d" title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen></iframe>
                    </div>
                </div>

            </section>
        );
    }
}
export default Banner;